<template>
  <v-dialog
    max-width="1000px"
    width="100%"
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
    @keydown.esc="dialog = false"
  >
    <v-card :loading="loading">
      <v-toolbar color="info" dark flat>
        <v-toolbar-items>
          <PersonItem :value="overview.person" />
        </v-toolbar-items>

        <v-spacer />
        <v-icon icon @click="dialog = false">mdi-close</v-icon>
      </v-toolbar>
      <v-toolbar flat v-if="!$vuetify.breakpoint.xs">
        <DateInput
          label="von"
          v-model="startDate"
          hideDetails
          class="mr-2"
          clearable
        ></DateInput>
        <DateInput
          label="bis"
          v-model="endDate"
          hideDetails
          class="mx-2"
          clearable
        ></DateInput>
        <v-btn
          class="mr-2"
          @click="
            startDate = '';
            endDate = '';
          "
          text
          >alle</v-btn
        >
        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-switch
                inset
                hide-details
                label="Anlass"
                v-model="event"
              ></v-switch>
            </div>
          </template>
          <span>nach Anlass gruppiert und sortiert</span>
        </v-tooltip>

        <template v-slot:extension>
          <v-tabs
            v-model="type"
            light
            background-color="white"
            color="info"
            centered
            ><v-tab>Beitrag Kanton</v-tab><v-tab>Eigene Kosten</v-tab></v-tabs
          >
        </template>
      </v-toolbar>
      <v-card-text class="ma-0 text-right">
        <template v-if="type === 0">
          <span class="mr-2">Beitrag Kanton</span>
          <v-chip light
            ><CurrencyValue :value="overview.balanceCantonal" />
          </v-chip>
        </template>
        <template v-else>
          <span class="mr-2">Eigene Kosten</span>
          <v-chip light>
            <CurrencyValue light :value="overview.balanceOwn" />
          </v-chip>
        </template>
      </v-card-text>
      <v-card-text class="ma-0 pa-0" v-if="!$vuetify.breakpoint.xs">
        <FinancePersonTableGrouped
          v-if="event"
          :items="items"
          :subtitle="overview.person"
        ></FinancePersonTableGrouped>
        <FinancePersonTableSimple
          v-else
          :items="items"
          :subtitle="overview.person"
        ></FinancePersonTableSimple>
      </v-card-text>
      <v-card-text class="ma-0 pa-0" v-else>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.id"
            :three-line="!!item.number"
          >
            <v-list-item-avatar
              ><PortraitImage :value="item.creator"
            /></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                <DateValue :value="item.date" />
              </v-list-item-subtitle>
              <v-list-item-title
                ><strong>{{ item.event ? item.event : "" }}</strong>
                {{ item.description }}</v-list-item-title
              >

              {{ item.number ? item.number : "" }}
            </v-list-item-content>
            <v-chip><CurrencyValue :value="item.amount" /></v-chip>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer /><v-btn text @click="dialog = false"
          >schliessen</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue";
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import FinancePersonTableSimple from "common/components/Finance/FinancePersonTableSimple.vue";
import FinancePersonTableGrouped from "common/components/Finance/FinancePersonTableGrouped.vue";
import PersonItem from "common/components/PersonItem.vue";
import PortraitImage from "common/components/PortraitImage.vue";

export default defineComponent({
  components: {
    DateValue,
    DateInput,
    CurrencyValue,
    FinancePersonTableSimple,
    FinancePersonTableGrouped,
    PersonItem,
    PortraitImage,
  },
  props: ["id"],
  data: () => ({
    event: false,
    overview: { student: {}, balance: 0 },
    items: [],
    loading: false,
    dialog: true,
    startDate: "",
    endDate: "",
    type: 1, // tab index: 0 === cantonal, 1 === own
  }),
  watch: {
    event() {
      if (this.event) {
        localStorage.setItem("financeGroupedEvents", true);
      } else {
        localStorage.removeItem("financeGroupedEvents");
      }
    },
    startDate() {
      this.fetchData();
    },
    endDate() {
      this.fetchData();
    },
    type() {
      this.fetchData();
    },
    dialog() {
      if (!this.dialog) {
        this.$router.push({
          name: "FinanceSchoolClass",
        });
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.overview = await this.apiGet({
        resource: "finance/personoverview",
        id: this.id,
      });
      this.items = await this.apiList({
        resource: "finance/persontransaction",
        query: `person=${this.id}&type=${
          this.type === 0 ? "cantonal" : "own"
        }&startDate=${this.startDate}&endDate=${this.endDate}`,
      });
      this.loading = false;
    },
  },

  async created() {
    this.event = localStorage.getItem("financeGroupedEvents");
    this.fetchData();
  },
});
</script>
